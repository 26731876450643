@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Poppins:wght@400;600&display=swap);
body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#title {
	color: white;
	font-weight: 600;
}
/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(167, 167, 167);
	border-radius: 10px;
}

.main-navbar {
	display: flex;
	min-height: 92vh;
	flex-direction: column;
	justify-content: space-between;
	background: aliceblue;
	
}
#title {
	color: white;
	font-weight: 600;
}

.profile {
	color: white !important;
	background: rgb(255, 35, 108) !important;
	width: 60px !important;
	height: 60px !important;
	font-size: 30px !important;
}

.profile-body {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

